<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="stepper-wrapper">
                                <div class="icon-tab col-xs-12 col-sm-2 col-sm-offset-3 stepper-item"
                                     :class="{'active': steps.current_step == 1, 'completed': steps.current_step >= 1}"
                                     @click.prevent="goToStep(1)">
                                    <div class="step-counter text-white">
                                        <font-awesome-icon :icon="['fas', 'file-alt']"></font-awesome-icon>
                                    </div>
                                    <span class="icon-label">Company Info</span>
                                </div>
                                <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                     :class="{'disable': steps.max_step < 2,'active': steps.current_step == 2,
                                     'completed': steps.current_step >= 2}"
                                     @click.prevent="goToStep(2)">
                                    <div class="step-counter text-white">
                                        <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
                                    </div>
                                    <span class="icon-label">User Info</span>
                                </div>
                                <div class="icon-tab col-xs-12 col-sm-2 stepper-item"
                                     :class="{'disable': steps.max_step < 3,'active': steps.current_step == 3,
                                     'completed': steps.current_step >= 3}"
                                     @click.prevent="goToStep(3)">
                                    <div class="step-counter text-white">
                                        <font-awesome-icon :icon="['fas', 'file']"></font-awesome-icon>
                                    </div>
                                    <span class="icon-label">Default documents</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Your elements -->
                    <Form ref="resetForm" @submit="submitForm(data.inputFormData)" v-slot="{ errors }">

                        <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 1">
                            <div class="panel panel-default row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                    <label class="form-control-label"> Company Name</label>
                                    <Field type="text" :class="{'border-danger': errors.company_name}"
                                           v-model="data.inputFormData.company_name"
                                           name="company_name" class="form-control" placeholder="Name"
                                           :rules="isRequired" @change="isDisabled()"/>
                                    <span class="text-danger" v-if="errors.company_name">
                                        {{ errors.company_name }}
                                    </span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                    <label class="form-control-label">Address</label>
                                    <textarea v-model="data.inputFormData.address" class="form-control"
                                              rows="2"></textarea>
                                </div>

                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Attach Logo</label>
                                    <input type="file" class="form-control" placeholder="logo"
                                           @change="getFormFile($event, 'logo')">
                                </div>

                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Subscription Type</label>
                                    <Field
                                        as="select"
                                        name="type_id"
                                        v-model="data.inputFormData.type_id"
                                        :rules="isRequired"
                                        class="form-control"
                                        :class="{'border-danger': errors.type_id}"
                                        @change="isDisabled()">
                                        <option value="">Subscription Type</option>
                                        <option v-for="item in subs_types()" :value="item.id">
                                            {{ item.type }}
                                        </option>
                                    </Field>
                                    <span class="text-danger" v-if="errors.type_id">{{ errors.type_id }}</span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Expire Date</label>
                                    <Field type="date" class="form-control"
                                           v-model="data.inputFormData.expired_at" @change="isDisabled()"
                                           :min="getToDate(true)" max="" name="expired_at" :rules="isRequired"
                                           :class="{'border-danger': errors.expired_at}"/>
                                    <span class="text-danger" v-if="errors.expired_at">
                                        {{ errors.expired_at }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 2">
                            <div class="panel panel-default row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                    <label class="form-control-label">Name</label>
                                    <Field type="text" class="form-control" placeholder="Name"
                                           v-model="data.inputFormData.name"
                                           name="name" :rules="isRequired" :class="{'border-danger': errors.name}"
                                           @change="isDisabled()"/>
                                    <span class="text-danger" v-if="errors.name">{{ errors.name }}</span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
                                    <label class="form-control-label">Email</label>
                                    <Field type="email" v-model="data.inputFormData.email" name="email"
                                           @change="isDisabled()"
                                           class="form-control" placeholder="Email" :rules="isRequired"
                                           :class="{'border-danger': errors.email}"/>
                                    <span class="text-danger" v-if="errors.email">{{ errors.email }}</span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Mobile</label>
                                    <Field type="tel" class="form-control" v-model="data.inputFormData.mobile"
                                           placeholder="Mobile"
                                           name="mobile" :rules="isRequired"
                                           :class="{'border-danger': errors.mobile}"/>
                                    <span class="text-danger" v-if="errors.mobile">{{ errors.mobile }}</span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Password</label>
                                    <password name="password"
                                              v-model="data.inputFormData.password"
                                              @change="isDisabled()"
                                              placeholder="Password"
                                              :rules="isRequired"
                                              :class="['form-control', errors.password ? 'border-danger' : '']"/>
                                    <span class="text-danger" v-if="errors.password">{{ errors.password }}</span>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 mb-3">
                                    <label class="form-control-label">Confirm Password</label>
                                    <password name="c_password"
                                              v-model="data.inputFormData.c_password"
                                              @change="isDisabled()"
                                              placeholder="Confirm Password"
                                              :rules="confirmPassword"
                                              :class="['form-control', errors.c_password ? 'border-danger' : '']"/>
                                    <span class="text-danger" v-if="errors.c_password">
                                        {{ errors.c_password }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="item col-sm-12 col-sm-offset-1" v-show="steps.current_step == 3">
                            <div class="panel panel-default">
                                <h3 class="border-bottom pb-3 mb-3">Default Documents</h3>
                                <div class="form-group row mb-3">
                                    <div class="col-sm-12">
                                        <div class="form-check" v-for="(list, index) in documents()" :key="index">
                                            <label class="form-check-label">
                                                {{ list.name }}
                                                <input class="form-check-input" type="checkbox" :value="list.id"
                                                       v-model="data.inputFormData.documents">
                                            </label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="text-right">
                                <button v-if="steps.max_step >= 2" type="submit" class="btn btn-primary">
                                    Submit
                                </button>
                                <button class="btn btn-green ms-2" type="button"
                                        v-if="steps.max_step == 1 || steps.max_step == 2" @click="nextStep()"
                                        :disabled="nextButtonCheck.isDisabled"
                                >Next
                                </button>

                                <router-link :to="$route.meta.backURL" type="button"
                                             class="btn btn-secondary ms-2">Cancel
                                </router-link>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
import {Field, Form} from "vee-validate";
import HelperFunction from "@/common/helpers";
import {reactive, onMounted, ref, watch} from 'vue'
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import Password from "@/common/components/input/Password";

export default {

    name: "Create Company",
    components: {
        Password,
        Form,
        Field
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        const {data, storeFormData, getFormFile, resetForm, formPermissionResolver, getToDate} = HelperFunction();

        /** form permission **/
        formPermissionResolver('/administrator/companies');
        /** end **/

        data.setURL = vueConfig.Company.CompanyEndPoint
        let steps = reactive({
            current_step: 1,
            max_step: 1,
        })
        data.inputFormData.type_id = ''
        data.inputFormData.documents = []
        let nextButtonCheck = reactive({
            isDisabled: 1
        })
        // CALL GETTERS
        onMounted(() => {
            //store.dispatch("staticDataRequest");
        })

        function isDisabled() {
            if (steps.current_step === 1) {
                if (data.inputFormData.company_name && data.inputFormData.type_id && data.inputFormData.expired_at) {
                    nextButtonCheck.isDisabled = 0
                }
            }
            if (steps.current_step === 2) {
                if (data.inputFormData.name && data.inputFormData.email && data.inputFormData.mobile &&
                    data.inputFormData.password && data.inputFormData.c_password) {
                    nextButtonCheck.isDisabled = 0
                    if (data.inputFormData.password != data.inputFormData.c_password) {
                        nextButtonCheck.isDisabled = 1
                    } else {
                        nextButtonCheck.isDisabled = 0
                    }
                }
            }
        }
        //TAB VALIDATION
        function validate() {
            if (steps.current_step === 1) {
                if (data.inputFormData.company_name == ('' || undefined) ||
                    data.inputFormData.type_id == ('' || undefined) ||
                    data.inputFormData.expired_at == ('' || undefined)) {
                    return false
                }
            }
            if (steps.current_step === 2) {
                if (data.inputFormData.name == ('' || undefined) ||
                    data.inputFormData.email == ('' || undefined) ||
                    data.inputFormData.password == ('' || undefined)) {
                    return false
                }
            }
            return true
        }
        function nextStep() {
            if (!validate()) {
                return
            }
            steps.current_step++
            if (steps.max_step < steps.current_step) {
                steps.max_step = steps.current_step
            }
            if (steps.max_step === 2) {
                nextButtonCheck.isDisabled = 1
            }
        }
        function goToStep(value) {
            if (!validate()) {
                return
            }
            steps.current_step = value
        }
        async function submitForm() {
            if (!validate()) {
                return
            }
            if (steps.max_step == 2 || steps.max_step == 3) {
                if (data.inputFormData.password != data.inputFormData.c_password) {
                    return false
                }
                data.inputFormData.documents = JSON.stringify(data.inputFormData.documents)
                // STORE DATA
                await storeFormData({url: data.setURL}, data.inputFormData,false,false,true)
                    .then((response) => {
                        if (response) {
                            router.push('/administrator/companies')
                        }
                    })
            }
        }
        function subs_types() {
            return store.getters.getSubscriptionTypes;
        }
        function documents() {
            return store.getters.getDocuments
        }

        //VALIDATION
        function confirmPassword(value) {
            if (data.inputFormData.password === value) {
                return true
            }
            return 'Passwords must match';
        }

        function isRequired(value) {
            if (value) {
                return true;
            }
            return "This field is required";
        }

        return {
            data, steps, nextStep, submitForm,
            goToStep, confirmPassword, isRequired,
            storeFormData, getFormFile, subs_types,
            documents, nextButtonCheck, isDisabled, resetForm, getToDate
        };
    },

}
/* eslint-disable */

</script>


<style src="@/assets/css/multiple-tab.css"/>
